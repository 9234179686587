import { Alert } from "components/Alert";
import { DemoSiteLoginInfo } from "components/DemoSiteLoginInfo";
import { Loader } from "components/Loader";
import { ExistingUserForm } from "components/site/ExistingUserForm";
import { Layout } from "components/site/Layout";
import { Title } from "components/site/Title";
import { isDemoSite } from "consts/client";
import useClient from "hooks/useClient";
import useCurrentUser from "hooks/useCurrentUser";
import Head from "next/head";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { FormTemplateType } from "types/form";

/**
 * @url `/login`
 */
export default function LoginPage() {
  const router = useRouter();

  const resetRedirect = Boolean(router.query.resetRedirect);
  const createRedirect = Boolean(router.query.createRedirect);
  const emailUpdateRedirect = Boolean(router.query.emailUpdateRedirect);

  const redirect = router.query.redirect as string | undefined;

  const { data: currentUser } = useCurrentUser();
  const { data: client, isLoading } = useClient();

  useEffect(() => {
    if (currentUser && !emailUpdateRedirect) {
      router.push(redirect || "/");
    }
  }, [currentUser, emailUpdateRedirect, redirect, router]);

  return (
    <Layout pageName="Log in">
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <Title>Log in to your account</Title>
      <p className="mt-2 text-center text-sm text-gray-600">
        Or{" "}
        <Link
          href={`/register${redirect ? `?redirect=${redirect}` : ""}`}
          className="font-medium text-indigo-600 hover:text-indigo-500"
        >
          register for the first time
        </Link>
      </p>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
            {isDemoSite && client && <DemoSiteLoginInfo client={client} />}
            {(resetRedirect || createRedirect || emailUpdateRedirect) && (
              <Alert variant="green" className="mb-4">
                {resetRedirect &&
                  "Thanks for resetting your password. Your new password has been saved. Please login below."}
                {createRedirect &&
                  "Thanks for creating your password. Your new password has been saved. Please login below."}
                {emailUpdateRedirect &&
                  "Thanks for updating your email address. This has now been saved. Please login using your new email address below."}
              </Alert>
            )}
            <ExistingUserForm
              redirect={redirect || "/"}
              submitLabel="Log in"
              formTemplate={FormTemplateType.Seamless}
            />
          </div>
        </div>
      )}
    </Layout>
  );
}
