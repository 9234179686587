import type { ReactNode } from "react";

export const Title = ({ children }: { children: ReactNode }) => (
  <h2
    className="mt-12 text-center text-3xl font-bold leading-9 text-gray-900"
    data-cy="title"
  >
    {children}
  </h2>
);
