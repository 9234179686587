import type { Client } from "types/model/client";

export const DemoSiteLoginInfo = ({ client }: { client: Client }) => {
  return (
    <div className="mb-4 text-gray-900">
      <p className="mb-4 text-sm">
        On this demo site you can log in as an admin user using the following
        details.
      </p>
      <div className="mt-2 text-sm text-gray-900">
        <ul className="list-disc space-y-1 pl-5">
          <li>
            Email address:{" "}
            <span className="font-mono font-bold">
              {client.subdomain}@pembee.online
            </span>
          </li>
          <li>
            Password: <span className="font-mono font-bold">password</span>
          </li>
        </ul>
      </div>
    </div>
  );
};
