import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import type { MutationResultPair } from "react-query";
import { queryCache, useMutation } from "react-query";
import type { ErrorResponse } from "types";
import type { AuthData } from "types/auth";

export default function useAuth(): MutationResultPair<
  AxiosResponse,
  AxiosError<ErrorResponse>,
  AuthData,
  unknown
> {
  return useMutation(
    data => {
      return axios.post("/api/auth", data);
    },
    {
      onSuccess: () => {
        queryCache.invalidateQueries("currentUser");
        queryCache.invalidateQueries("cart");
      }
    }
  );
}
